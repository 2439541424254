<app-headerhome class="header-sec"></app-headerhome>
<div class="theme-page-section theme-page-section-dark">
        <div class="container">
          <div class="theme-search-area _mob-h theme-search-area-white">
            <div class="row">
              <div class="col-md-2 ">
                <div class="theme-search-area-header theme-search-area-header-sm">
                  <h1 class="theme-search-area-title">{{ pickUpInit }} - {{ dropOffInit }}</h1>
                  <p class="theme-search-area-subtitle">{{ travelDateInit }}</p>
                </div>
              </div>
              <div class="col-md-10 ">
                <div class="theme-search-area-form" id="hero-search-form">
                    <form  autocomplete="off" novalidate #reactiveForm = "ngForm" (ngSubmit)="submitWebForm(reactiveForm.value)">
                  <div class="row" data-gutter="10">

                        <div class="col-md-3 ">
                          <div class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerpickup">
                                <!--Using items input-->
                                <ng-select [items]="data" 
                                bindLabel="text" 
                                bindValue="id" 
                                name="pickup"
                                [(ngModel)]="pickup">
                                </ng-select>

                            </div>
                          </div>
                        </div>
                        <div class="col-md-1">
                            <div (click)="interChange()" class=" _mt-20 SwitchDirectionsStyles__StyledSwitchDirections-sc-14fk104-2 lcGjiX"><span
                                data-test="SwitchDirections-inner" role="button" aria-label="Switch direction" tabindex="0"
                                class="SwitchDirectionsStyles__SwitchDirectionsLink-sc-14fk104-1 dnQkMW">

                                <svg id="Layer" enable-background="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                                  <g fill="#012e52">
                                    <path d="m48.414 15.586c-.781-.781-2.047-.781-2.828 0s-.781 2.047 0 2.828l3.586 3.586h-39.172c-1.104 0-2 .896-2 2s.896 2 2 2h39.172l-3.586 3.586c-.781.781-.781 2.047 0 2.828.391.391.902.586 1.414.586s1.023-.195 1.414-.586l7-7c.781-.781.781-2.047 0-2.828z"/>
                                    <path d="m54 38h-39.172l3.586-3.586c.781-.781.781-2.047 0-2.828s-2.047-.781-2.828 0l-7 7c-.781.781-.781 2.047 0 2.828l7 7c.391.391.902.586 1.414.586s1.023-.195 1.414-.586c.781-.781.781-2.047 0-2.828l-3.586-3.586h39.172c1.104 0 2-.896 2-2s-.896-2-2-2z"/>
                                  </g>
                                </svg>
                            </span>
                            </div>
                         </div>
                        <div class="col-md-3 ">
                          <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerdropoff">
                              
                                <ng-select [items]="data" 
                                bindLabel="text" 
                                bindValue="id" 
                                name="dropoff"
                                [(ngModel)]="dropoff">
                                </ng-select>
                            </div>
                          </div>
                        </div>

                    <div class="col-md-3 ">
                      <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                        <div class="theme-search-area-section-inner">
                            <i class="theme-search-area-section-icon lin lin-calendar"></i>
                            <input required autocomplete="off" type="text"
                            placeholder="Datepicker"
                            class="theme-search-area-section-input"
                            name="traveldate"
                            [(ngModel)]="traveldate"
                            [minDate]="today"
                            #dp="bsDatepicker"
                            bsDatepicker
                            [bsConfig]="{ isAnimated: true,adaptivePosition: true }"
                            [bsValue]="bsValue">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <button class="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-sm theme-search-area-submit-primary">Search</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="theme-search-area-inline _desk-h theme-search-area-inline-white">
            <h4 class="theme-search-area-inline-title">{{ this.bookingdetails.pickupname.includes("Nairobi(CB)") || this.bookingdetails.pickupname.includes("Nairobi(CB2)") ? this.bookingdetails.pickupname.replace("Nairobi(CB", "Nairobi( Regular Bus ") : this.bookingdetails.pickupname  }} - {{this.bookingdetails.dropoffname}}</h4>
            <p class="theme-search-area-inline-details">{{this.bookingdetails.traveldate}}</p>
            
           
          </div>
        </div>
</div>
    <div class="theme-page-section margin-grey">
      <div class="container">
        <div class="bus-listing-page  header-displayed">
            <div class="page-content row">
                <div class="col-md-12">
                        <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
                </div>
                <div class=" col-md-12" id="search-results-wrapper">
                    <div class="col-md-12">
                            <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
                    </div>
                <h2 class="city-labels">
                  {{fromPlaceholder}}
                  →
                  {{toPlaceholder}}
                </h2>
                <div class="row">
                        <div class="col-lg-12 col-no-gap visible-sm visible-lg">
                                <ul class="dates ">
                                        <li class="prev-container">
                                            <a *ngIf="!checkDates()" class="prev"  (click)="changeDate(beforeyesterday)" >
                                            ‹
                                    </a></li>
                                    <li  class="yesterday-container">
                                        <a *ngIf="!checkDates()" class="yesterday"  (click)="changeDate(yesterday)">
                                            <strong>
                                                    {{yesterday  | date:'EEEE, d MMM'}}
                                            </strong></a>
                                    </li>
                                    <li class="active-container"><a class="active" [routerLink]="[]"  (click)="changeDate(travelDateInit)" >
                                            <strong>
                                                    {{travelDateInit  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="tomorrow-container"><a class="tomorrow" [routerLink]="[]" (click)="changeDate(tomorrow)">
                                            <strong>
                                                    {{tomorrow  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="next-container"><a class="next" [routerLink]="[]" (click)="changeDate(aftertomorrow)">
                                            ›
                                        </a></li>
                                </ul>
                        </div>
                </div>
                <div class="theme-search-results search-result-multiselect">

                    <div class="theme-search-results-item search-result" id="search-result-direct">
                        <div class="row">

                            <div class="b col-lg-12 rides-list currency-eur">
                              <div class="sr-head row _mob-h visible-sm visible-lg">
                                  <div class="col-lg-4 col-lg-offset-2 time-transfer">
                                      <div class="row">
                                          <div class="col-lg-12 _mob-h ride-timing">Departs/Arrives
                                              </div>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="row">
                                          <div class="col-lg-10  visible-sm visible-lg">Depature Time
                                              </div>
                                          <div class="col-lg-2 ">
                                              <div class="row">
                                                  <div class="col-lg-12 col-sm-4 col-md-12 col-lg-5 total">
                                                      Total
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div id="results-group-container-direct" class="result-date-container">
                                        <app-results-loader *ngIf="isResultsLoading"></app-results-loader>
                                        <app-nobuses  *ngIf="resultsholder.length == 0"></app-nobuses>
                                    <div  *ngFor="let result of results;let i = index" class=" ride-item-pair  row" >
                                        
                                        <div class="col-lg-12">
                                            <div class="sr-row row  ride--booked js-ride--booked _pd-10">
                                              <div  class="col-lg-4 time-transfer">
                                                  <img  class="mobile-images _mt-10" width="100" alt="Greenline Bus" src="assets/img/images/greenline-logo.webp">
                                                  <br >
                                                  <!-- <p *ngIf="!result.fleet.registration_number.includes(fromPlaceholder+'-')" class="text-danger  badge _mb-10">Highway bus</p> -->
                                                  <div class=" d-block d-sm-none d-md-block d-block d-md-none  d-block d-lg-none   ">
                                                    <small>{{ result.schedule.start_time | convertTime }}</small>
                                               
                                                 </div>
                                              </div>
                                                <div class="col-lg-8">
                                                    <div class="row">
                                                        <div class=" text-center col-lg-4 visible-sm visible-lg">
                                                            <div class="row">
                                                                <div  class="col-lg-12 ">
                                                                  <div class=" ride-stations">
                                                                      <div  class="bus-info u-ib u-v-align-middle">
                                                                    	<h6  class="left-wing city-labels-list u-ib _mr-5">
                                                                         {{ result.route.name.includes("Nairobi(CB)") || result.route.name.includes("Nairobi(CB2)") ? result.route.name.replace("Nairobi(CB", "Nairobi( Regular Bus ") :result.route.name }}
                                                                    	</h6>
                                                                    	<!-- <div  class="timeline-widget u-ib">
                                                                    		<div  class="c-timeline-wrapper horizontal">
                                                                    			<div  class="label tl first" style="font-size: 20px;"></div>
                                                                    			<div  class="label tl last" style="font-size: 20px;"></div>
                                                                    			<div  class="c-timeline-booking" style="height: 3px; background-color: rgb(187, 187, 187);">
                                                                    			</div>
                                                                    			<div  class="label br first" style="font-size: 20px;"></div>
                                                                    			<div  class="label br" style="font-size: 20px;"></div>
                                                                    			<div  class="label br last" style="font-size: 20px;"></div>
                                                                    		</div>
                                                                    	</div> -->
                                                                    	<!-- <h6  class="right-wing city-labels-list u-ib _ml-5">
                                                                    		  {{ dropOffInit}}
                                                                    	</h6> -->
                                                                    </div>
                                                                  </div>
                                                                    <div class="duration ride__duration ride__duration-messages text-center">
                                                                         {{ result.schedule.start_time | convertTime}}
                                                                   </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-sm-8 col-lg-8 price-actions">
                                                            <div class="row">
                                                                <div
                                                                    class="col-lg-12 col-sm-4 col-md-12 col-lg-5 ">

                                                                    <div class="in-cart total"><span
                                                                            class="num pull-right _pb-10">Ksh {{result.seat_price.normal}}</span>
                                                                    </div>

                                                                </div>
                                                               
                                                                <div class="col-lg-12 col-sm-8 col-md-12 col-lg-7 action">
                                                                   <button type="submit" class="js-ride__action__form__btn reserve"  data-toggle="modal" data-target="#exampleModal" style="box-sizing: border-box;" (click)="selectSeats(result)">Select Seat</button>
                                                                   <div class="seats-notice">
                                                                        {{result.fleet.fleet_total_seats - (result.fleet.booked_seats.split(",").length - 1)}} seats left
                                                                     </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                <div *ngIf="dataLoading" class="seat-layout-loader"><div class="c-loader"><div class="c-loader-inner"><div class="loader"></div></div></div><span class="close-button ixi-icon-cross"></span></div>
                                                  <div  *ngIf="result.hide_from_ui">
                                                        <div class="modal left fade" id="exampleModal" tabindex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog" role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                         
                                                                            <div class="seat-book-cntr-mobile">
                                                                                <div class="center-wrapper">
                                                                                    <div  class="seat-layout">
                                                                                        <div class="seat-layout-cntr">
                                                                                            <div class="seat-legends">
                                                                                                <div class="seat-legend u-ib">
                                                                                                    <div class="single-seat-legend u-ib ">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                    </div>
                                                                                                    <div class="u-ib text">Available</div>
                                                                                                </div>
                                                                                                <div class="seat-legend u-ib">
                                                                                                    <div class="single-seat-legend u-ib unavailable">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                    </div>
                                                                                                    <div class="u-ib text">Occupied</div>
                                                                                                </div>
                                                                                                <div class="seat-legend u-ib">
                                                                                                    <div class="single-seat-legend u-ib selected">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                    </div>
                                                                                                    <div class="u-ib text">Selecteddd</div>
                                                                                                </div>
                    
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true" class="la la-close"></span>
                                                                              </button>
                                                                    </div>
                                                                     
                                                                      
                                                                     
                                                                    <div class="modal-body">
                                                                        <div class=" flex-sm-column flex-row">
                                                                            <div class="seat-book-cntr-mobile  "  *ngIf="result.hide_from_ui">
                                                                                <div class="center-wrapper">
                                                                                    <div *ngIf="result.fleet.fleet_total_seats == 37" class="seat-layout">
                                                                                        <div class="seat-layout-cntr">
                                                                                            <div class="seat-layout">
                                                                                                <div class="seat-rows u-ib">
                                                                                                  <div class="bus-steering u-ib">
                                                                                                      <div class="seat-row mt_5">
                                                                                                          <div class="no-single-seat"></div>
                                                                                                          <div class="no-single-seat"></div>
                                                                                                          <div class="no-single-seat"></div>
                                                                                                          <div class="no-single-seat"></div>
                                                                                                          <div class="no-single-seat">
                                                                                                              <img class="" src="assets/img/greenline/bus_steering.png">
                                                                                                          </div>
                                                                                                        </div>
                        
                        
                                                                                                </div>
                        
                                                                                                <div class="first-seats">
                                                                                                    <div class="seat-row mt_5">
                                                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('1A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1A')" >
                                                                                                            <div class="top"></div>
                                                                                                            <div class="right"></div>
                                                                                                            <div class="bottom"></div>
                                                                                                            <div class="back"></div>
                                                                                                            <div class="seat-no">1A</div>
                                                                                                        </div>
                                                                                                        <div class="no-single-seat"></div>
                                                                                                        <div class="no-single-seat"></div>
                                                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('1C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1C')" >
                                                                                                            <div class="top"></div>
                                                                                                            <div class="right"></div>
                                                                                                            <div class="bottom"></div>
                                                                                                            <div class="back"></div>
                                                                                                            <div class="seat-no">1C</div>
                                                                                                        </div>
                                                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('1D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1D')" >
                                                                                                            <div class="top"></div>
                                                                                                            <div class="right"></div>
                                                                                                            <div class="bottom"></div>
                                                                                                            <div class="back"></div>
                                                                                                            <div class="seat-no">1D</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="seat-row">
                                                                                                    <div [ngClass]="(result.fleet.seat_numbers.includes('2A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('2A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '2A')" >
                                                                                                        <div class="top"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">2A</div>
                                                                                                    </div>
                                                                                                    <div class="no-single-seat"></div>
                                                                                                    <div class="no-single-seat"></div>
                                                                                                    <div [ngClass]="(result.fleet.seat_numbers.includes('2C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('2C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '2C')" >
                                                                                                        <div class="top"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">2C</div>
                                                                                                    </div>
                                                                                                    <div [ngClass]="(result.fleet.seat_numbers.includes('2D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('2D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '2D')" >
                                                                                                        <div class="top"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">2D</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="seat-row">
                                                                                                    <div [ngClass]="(result.fleet.seat_numbers.includes('3A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3A')" >
                                                                                                        <div class="top"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">3A</div>
                                                                                                    </div>
                                                                                                    <div class="no-single-seat"></div>
                                                                                                    <div class="no-single-seat"></div>
                                                                                                    <div [ngClass]="(result.fleet.seat_numbers.includes('3C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3C')" >
                                                                                                        <div class="top"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">3C</div>
                                                                                                    </div>
                                                                                                    <div [ngClass]="(result.fleet.seat_numbers.includes('3D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3D')" >
                                                                                                        <div class="top"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">3D</div>
                                                                                                    </div>
                                                                                              </div>
                                                                                              <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '4A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">4A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '4C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">4C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '4D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">4D</div>
                                                                                                </div>
                                                                                            </div>
                        
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">5A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">5C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">5D</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">6A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">6C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">6D</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">7A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">7C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">7D</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">8A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">8C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">8D</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">9A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">9C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">9D</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">10A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">10C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">10D</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">11A</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">11C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">11D</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12A')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">12A</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12B')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">12B</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12C')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12C')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12C')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">12C</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12D')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12D')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12D')" >
                                                                                                    <div class="top"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">12D</div>
                                                                                                </div>
                                                                                            </div>
                        
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> 
                                                                                    
                                                                                    
                                                                                </div>
                                                                                <div class="center-wrapper" *ngIf="result.fleet.fleet_total_seats ==48">
                                                                                    <div class="seat-layout-cntr">
                                                                                        <div class="seat-layout-heading">SELECT A SEAT</div>
                                                                                        <div class="seat-layout">
                                                                                            <div class="seat-rows u-ib">
                                                                                              <div class="bus-steering u-ib">
                                                                                                  <div class="seat-row mt_5">
                                                                                                      <div [ngClass]="(result.fleet.seat_numbers.includes('1'))?'':'unavailable'"  (click)="seatSelect($event,result,1)"  class="seat u-ib single-seat ">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">1</div>
                                                                                                      </div>
                                                                                                      <div  [class.disabled]="isUnavailabe" class="seat u-ib single-seat unavailable">
                                                                                                          <div class="top"></div>
                                                                                                          <div class="left"></div>
                                                                                                          <div class="right"></div>
                                                                                                          <div class="bottom"></div>
                                                                                                          <div class="back"></div>
                                                                                                          <div class="seat-no">X</div>
                                                                                                      </div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat">
                                                                                                          <img class="" src="assets/img/greenline/bus_steering.png">
                                                                                                      </div>
                                                                                                    </div>
                                                
                                                
                                                                                            </div>
                                                
                                                                                            <div class="first-seats">
                                                                                                <div class="seat-row mt_5">
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('5'))?'':'unavailable'" (click)="seatSelect($event,result, 5)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">5</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('6'))?'':'unavailable'" (click)="seatSelect($event,result, 6)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">6</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('3'))?'':'unavailable'" (click)="seatSelect($event,result, 3)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">3</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('4'))?'':'unavailable'" (click)="seatSelect($event,result, 4)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">4</div>
                                                                                                  </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('9'))?'':'unavailable'" (click)="seatSelect($event,result, 9)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">9</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('10'))?'':'unavailable'" (click)="seatSelect($event,result, 10)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">10</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('7'))?'':'unavailable'" (click)="seatSelect($event,result, 7)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">7</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('8'))?'':'unavailable'" (click)="seatSelect($event,result, 8)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">8</div>
                                                                                                  </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11'))?'':'unavailable'" (click)="seatSelect($event,result, 11)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">11</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12'))?'':'unavailable'" (click)="seatSelect($event,result, 12)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">12</div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <div class="seat-row">
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('13'))?'':'unavailable'" (click)="seatSelect($event,result, 13)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">13</div>
                                                                                              </div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('14'))?'':'unavailable'" (click)="seatSelect($event,result, 14)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">14</div>
                                                                                              </div>
                                                                                              <div class="no-single-seat"></div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('15'))?'':'unavailable'" (click)="seatSelect($event,result, 15)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">15</div>
                                                                                              </div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('16'))?'':'unavailable'" (click)="seatSelect($event,result, 16)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">16</div>
                                                                                              </div>
                                                                                        </div>
                                                
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('17'))?'':'unavailable'" (click)="seatSelect($event,result, 17)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">17</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('18'))?'':'unavailable'" (click)="seatSelect($event,result, 18)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">18</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('19'))?'':'unavailable'" (click)="seatSelect($event,result, 19)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">19</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('20'))?'':'unavailable'" (click)="seatSelect($event,result, 20)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">20</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('21'))?'':'unavailable'" (click)="seatSelect($event,result, 21)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">21</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('22'))?'':'unavailable'" (click)="seatSelect($event,result, 22)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">22</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('23'))?'':'unavailable'" (click)="seatSelect($event,result, 23)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">23</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('24'))?'':'unavailable'" (click)="seatSelect($event,result, 24)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">24</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('25'))?'':'unavailable'" (click)="seatSelect($event,result, 25)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">25</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('26'))?'':'unavailable'" (click)="seatSelect($event,result, 26)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">26</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('27'))?'':'unavailable'" (click)="seatSelect($event,result, 27)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">27</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('28'))?'':'unavailable'" (click)="seatSelect($event,result, 28)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">28</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('29'))?'':'unavailable'" (click)="seatSelect($event,result, 29)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">29</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('30'))?'':'unavailable'" (click)="seatSelect($event,result, 30)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">30</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('31'))?'':'unavailable'" (click)="seatSelect($event,result, 31)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">31</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('32'))?'':'unavailable'" (click)="seatSelect($event,result, 32)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">32</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('33'))?'':'unavailable'" (click)="seatSelect($event,result, 33)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">33</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('34'))?'':'unavailable'" (click)="seatSelect($event,result, 34)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">34</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('35'))?'':'unavailable'" (click)="seatSelect($event,result, 35)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">35</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('36'))?'':'unavailable'" (click)="seatSelect($event,result, 36)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">36</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('37'))?'':'unavailable'" (click)="seatSelect($event,result, 37)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">37</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('38'))?'':'unavailable'" (click)="seatSelect($event,result, 38)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">38</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('39'))?'':'unavailable'" (click)="seatSelect($event,result, 39)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">39</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('40'))?'':'unavailable'" (click)="seatSelect($event,result, 40)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">40</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('41'))?'':'unavailable'" (click)="seatSelect($event,result, 41)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">41</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('42'))?'':'unavailable'" (click)="seatSelect($event,result, 42)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">42</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('43'))?'':'unavailable'" (click)="seatSelect($event,result, 43)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">43</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('44'))?'':'unavailable'" (click)="seatSelect($event,result, 44)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">44</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('45'))?'':'unavailable'" (click)="seatSelect($event,result, 45)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">45</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('46'))?'':'unavailable'" (click)="seatSelect($event,result, 46)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">46</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('47'))?'':'unavailable'" (click)="seatSelect($event,result, 47)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">47</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('48'))?'':'unavailable'" (click)="seatSelect($event,result, 48)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">48</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('49'))?'':'unavailable'" (click)="seatSelect($event,result, 49)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">49</div>
                                                                                            </div>
                                                                                        </div>
                                                
                                                                                            </div>
                                                                                        </div>
                                                
                                                                                    </div>
                                                
                                                                                </div>
                                                                                <div class="center-wrapper" *ngIf="result.fleet.fleet_total_seats ==40">
                                                                                    <div class="seat-layout-cntr">
                                                                                        <div class="seat-layout-heading">SELECT A SEAT</div>
                                                                                        <div class="seat-layout">
                                                                                            <div class="seat-rows u-ib">
                                                                                              <div class="bus-steering u-ib">
                                                                                                  <div class="seat-row mt_5">
                                                                                                      <div [ngClass]="(result.fleet.seat_numbers.includes('1'))?'':'unavailable'"  (click)="seatSelect($event,result,1)"  class="seat u-ib single-seat ">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">1</div>
                                                                                                      </div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat">
                                                                                                          <img class="" src="assets/img/greenline/bus_steering.png">
                                                                                                      </div>
                                                                                                    </div>
                                                
                                                
                                                                                            </div>
                                                
                                                                                            <div class="first-seats">
                                                                                                <div class="seat-row mt_5">
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('3'))?'':'unavailable'" (click)="seatSelect($event,result, 3)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">3</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('5'))?'':'unavailable'" (click)="seatSelect($event,result, 5)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">5</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('7'))?'':'unavailable'" (click)="seatSelect($event,result, 7)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">7</div>
                                                                                                  </div>
                                                
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                              <div class="no-single-seat"></div>
                                                                                              <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('8'))?'':'unavailable'" (click)="seatSelect($event,result, 8)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">8</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('10'))?'':'unavailable'" (click)="seatSelect($event,result, 10)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">10</div>
                                                                                                  </div>
                                                
                                                
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11'))?'':'unavailable'" (click)="seatSelect($event,result, 11)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">11</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('13'))?'':'unavailable'" (click)="seatSelect($event,result, 13)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">13</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('15'))?'':'unavailable'" (click)="seatSelect($event,result, 15)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">15</div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <div class="seat-row">
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('16'))?'':'unavailable'" (click)="seatSelect($event,result, 16)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">16</div>
                                                                                              </div>
                                                                                              <div class="no-single-seat"></div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('18'))?'':'unavailable'" (click)="seatSelect($event,result, 18)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">18</div>
                                                                                              </div>
                                                                                              <div class="no-single-seat"></div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('20'))?'':'unavailable'" (click)="seatSelect($event,result, 20)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">20</div>
                                                                                              </div>
                                                
                                                                                        </div>
                                                
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('21'))?'':'unavailable'" (click)="seatSelect($event,result, 21)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">21</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('23'))?'':'unavailable'" (click)="seatSelect($event,result, 23)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">23</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('25'))?'':'unavailable'" (click)="seatSelect($event,result, 25)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">25</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('26'))?'':'unavailable'" (click)="seatSelect($event,result, 26)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">26</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('28'))?'':'unavailable'" (click)="seatSelect($event,result, 28)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">28</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('30'))?'':'unavailable'" (click)="seatSelect($event,result, 30)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">30</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('31'))?'':'unavailable'" (click)="seatSelect($event,result, 31)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">31</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('33'))?'':'unavailable'" (click)="seatSelect($event,result, 33)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">33</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('35'))?'':'unavailable'" (click)="seatSelect($event,result, 35)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">35</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('36'))?'':'unavailable'" (click)="seatSelect($event,result, 36)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">36</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('38'))?'':'unavailable'" (click)="seatSelect($event,result, 38)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">38</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('40'))?'':'unavailable'" (click)="seatSelect($event,result, 40)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">40</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('41'))?'':'unavailable'" (click)="seatSelect($event,result, 41)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">41</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('43'))?'':'unavailable'" (click)="seatSelect($event,result, 43)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">43</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('45'))?'':'unavailable'" (click)="seatSelect($event,result, 45)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">45</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('46'))?'':'unavailable'" (click)="seatSelect($event,result, 46)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">46</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('48'))?'':'unavailable'" (click)="seatSelect($event,result, 48)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">48</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('50'))?'':'unavailable'" (click)="seatSelect($event,result, 50)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">50</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('51'))?'':'unavailable'" (click)="seatSelect($event,result, 51)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">51</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('53'))?'':'unavailable'" (click)="seatSelect($event,result, 53)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">53</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('55'))?'':'unavailable'" (click)="seatSelect($event,result, 55)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">55</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('56'))?'':'unavailable'" (click)="seatSelect($event,result, 56)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">56</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('58'))?'':'unavailable'" (click)="seatSelect($event,result, 58)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">58</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('60'))?'':'unavailable'" (click)="seatSelect($event,result, 60)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">60</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('61'))?'':'unavailable'" (click)="seatSelect($event,result, 61)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">61</div>
                                                                                            </div>
                                                
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('63'))?'':'unavailable'" (click)="seatSelect($event,result, 63)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">63</div>
                                                                                            </div>
                                                
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('64'))?'':'unavailable'" (click)="seatSelect($event,result, 64)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">64</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('66'))?'':'unavailable'" (click)="seatSelect($event,result, 66)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">66</div>
                                                                                            </div>
                                                                                        </div>
                                                
                                                                                            </div>
                                                                                        </div>
                                                
                                                                                    </div>
                                                
                                                                                </div>
                                                                                <div class="center-wrapper" *ngIf="result.fleet.fleet_total_seats ==36">
                                                                                    <div class="seat-layout-cntr">
                                                                                        <div class="seat-layout-heading">SELECT A SEAT</div>
                                                                                        <div class="seat-layout">
                                                                                            <div class="seat-rows u-ib">
                                                                                              <div class="bus-steering u-ib">
                                                                                                  <div class="seat-row mt_5">
                                                                                                      <div [ngClass]="(result.fleet.seat_numbers.includes('1'))?'':'unavailable'"  (click)="seatSelect($event,result,1)"  class="seat u-ib single-seat ">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">1</div>
                                                                                                      </div>
                                                                                                      <div  [class.disabled]="isUnavailabe" class="seat u-ib single-seat unavailable">
                                                                                                          <div class="top"></div>
                                                                                                          <div class="left"></div>
                                                                                                          <div class="right"></div>
                                                                                                          <div class="bottom"></div>
                                                                                                          <div class="back"></div>
                                                                                                          <div class="seat-no">X</div>
                                                                                                      </div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat">
                                                                                                          <img class="" src="assets/img/greenline/bus_steering.png">
                                                                                                      </div>
                                                                                                    </div>
                                                
                                                
                                                                                            </div>
                                                
                                                                                            <div class="first-seats">
                                                                                                <div class="seat-row mt_5">
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('2'))?'':'unavailable'" (click)="seatSelect($event,result, 2)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">2</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('3'))?'':'unavailable'" (click)="seatSelect($event,result, 3)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">3</div>
                                                                                                  </div>
                                                                                                    <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('4'))?'':'unavailable'" (click)="seatSelect($event,result, 4)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">4</div>
                                                                                                  </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('5'))?'':'unavailable'" (click)="seatSelect($event,result, 5)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">5</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('6'))?'':'unavailable'" (click)="seatSelect($event,result, 6)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">6</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('7'))?'':'unavailable'" (click)="seatSelect($event,result, 7)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">7</div>
                                                                                                  </div>
                                                
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8'))?'':'unavailable'" (click)="seatSelect($event,result, 8)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">8</div>
                                                                                                </div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9'))?'':'unavailable'" (click)="seatSelect($event,result, 9)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">9</div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <div class="seat-row">
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('10'))?'':'unavailable'" (click)="seatSelect($event,result, 10)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">10</div>
                                                                                              </div>
                                                                                              <div class="no-single-seat"></div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('11'))?'':'unavailable'" (click)="seatSelect($event,result, 11)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">11</div>
                                                                                              </div>
                                                                                              <div class="no-single-seat"></div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('12'))?'':'unavailable'" (click)="seatSelect($event,result, 12)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">12</div>
                                                                                              </div>
                                                                                        </div>
                                                
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('13'))?'':'unavailable'" (click)="seatSelect($event,result, 13)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">13</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('14'))?'':'unavailable'" (click)="seatSelect($event,result, 14)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">14</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('15'))?'':'unavailable'" (click)="seatSelect($event,result, 15)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">15</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('16'))?'':'unavailable'" (click)="seatSelect($event,result, 16)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">16</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('17'))?'':'unavailable'" (click)="seatSelect($event,result, 17)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">17</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('18'))?'':'unavailable'" (click)="seatSelect($event,result, 18)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">18</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('19'))?'':'unavailable'" (click)="seatSelect($event,result, 19)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">19</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('20'))?'':'unavailable'" (click)="seatSelect($event,result, 20)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">20</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('21'))?'':'unavailable'" (click)="seatSelect($event,result, 21)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">21</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('22'))?'':'unavailable'" (click)="seatSelect($event,result, 22)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">22</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('23'))?'':'unavailable'" (click)="seatSelect($event,result, 23)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">23</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('24'))?'':'unavailable'" (click)="seatSelect($event,result, 24)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">24</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('25'))?'':'unavailable'" (click)="seatSelect($event,result, 25)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">25</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('26'))?'':'unavailable'" (click)="seatSelect($event,result, 26)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">26</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('27'))?'':'unavailable'" (click)="seatSelect($event,result, 27)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">27</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('28'))?'':'unavailable'" (click)="seatSelect($event,result, 28)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">28</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('29'))?'':'unavailable'" (click)="seatSelect($event,result, 29)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">29</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('30'))?'':'unavailable'" (click)="seatSelect($event,result, 30)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">30</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('31'))?'':'unavailable'" (click)="seatSelect($event,result, 31)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">31</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('32'))?'':'unavailable'" (click)="seatSelect($event,result, 32)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">32</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('33'))?'':'unavailable'" (click)="seatSelect($event,result, 33)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">33</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('34'))?'':'unavailable'" (click)="seatSelect($event,result, 34)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">34</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('35'))?'':'unavailable'" (click)="seatSelect($event,result, 35)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">35</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('36'))?'':'unavailable'" (click)="seatSelect($event,result, 36)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">36</div>
                                                                                            </div>
                                                
                                                                                        </div>
                                                
                                                                                            </div>
                                                                                        </div>
                                                
                                                                                    </div>
                                                
                                                                                </div>
                                                                                <div class="center-wrapper" *ngIf="result.fleet.fleet_total_seats == 67">
                                                                                    <div class="seat-layout-cntr">
                                                                                        <div class="seat-layout">
                                                                                            <div class="seat-rows u-ib">
                                                                                              <div class="bus-steering u-ib">
                                                                                                  <div class="seat-row mt_5">
                                                                                                      <div [ngClass]="(result.fleet.seat_numbers.includes('1'))?'':'unavailable'"  (click)="seatSelect($event,result,1)"  class="seat u-ib single-seat ">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                        <div class="seat-no">1X</div>
                                                                                                      </div>
                                                                                                      <div  [class.disabled]="isUnavailabe" class="seat u-ib single-seat unavailable">
                                                                                                          <div class="top"></div>
                                                                                                          <div class="left"></div>
                                                                                                          <div class="right"></div>
                                                                                                          <div class="bottom"></div>
                                                                                                          <div class="back"></div>
                                                                                                          <div class="seat-no">X</div>
                                                                                                      </div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat"></div>
                                                                                                      <div class="no-single-seat">
                                                                                                          <img class="" src="assets/img/greenline/bus_steering.png">
                                                                                                      </div>
                                                
                                                
                                                                                                    </div>
                                                
                                                
                                                                                            </div>
                                                
                                                                                            <div class="first-seats">
                                                                                                <div class="seat-row mt_5">
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('1'))?'':'unavailable'" (click)="seatSelect($event,result, 1)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">1</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('2'))?'':'unavailable'" (click)="seatSelect($event,result, 2)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">2</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div class="no-single-seat"></div>
                                                
                                                
                                                
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="seat-row">
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('3'))?'':'unavailable'" (click)="seatSelect($event,result, 3)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">3</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('4'))?'':'unavailable'" (click)="seatSelect($event,result, 4)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">4</div>
                                                                                                  </div>
                                                                                                  <div class="no-single-seat"></div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('5'))?'':'unavailable'" (click)="seatSelect($event,result, 5)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">5</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('6'))?'':'unavailable'" (click)="seatSelect($event,result, 6)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">6</div>
                                                                                                  </div>
                                                                                                  <div [ngClass]="(result.fleet.seat_numbers.includes('7'))?'':'unavailable'" (click)="seatSelect($event,result, 7)" class="seat u-ib single-seat">
                                                                                                      <div class="top"></div>
                                                                                                      <div class="left"></div>
                                                                                                      <div class="right"></div>
                                                                                                      <div class="bottom"></div>
                                                                                                      <div class="back"></div>
                                                                                                      <div class="seat-no">7</div>
                                                                                                  </div>
                                                                                            </div>
                                                
                                                                                            <div class="seat-row">
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div class="no-single-seat"></div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8'))?'':'unavailable'" (click)="seatSelect($event,result, 8)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">8</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9'))?'':'unavailable'" (click)="seatSelect($event,result, 9)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">9</div>
                                                                                                </div>
                                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10'))?'':'unavailable'" (click)="seatSelect($event,result, 10)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">10</div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <div class="seat-row">
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('11'))?'':'unavailable'" (click)="seatSelect($event,result, 11)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">11</div>
                                                                                              </div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('12'))?'':'unavailable'" (click)="seatSelect($event,result, 12)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">12</div>
                                                                                              </div>
                                                                                              <div class="no-single-seat"></div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('13'))?'':'unavailable'" (click)="seatSelect($event,result, 13)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">13</div>
                                                                                              </div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('14'))?'':'unavailable'" (click)="seatSelect($event,result, 14)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">14</div>
                                                                                              </div>
                                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('15'))?'':'unavailable'" (click)="seatSelect($event,result, 15)" class="seat u-ib single-seat">
                                                                                                  <div class="top"></div>
                                                                                                  <div class="left"></div>
                                                                                                  <div class="right"></div>
                                                                                                  <div class="bottom"></div>
                                                                                                  <div class="back"></div>
                                                                                                  <div class="seat-no">15</div>
                                                                                              </div>
                                                                                        </div>
                                                
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('16'))?'':'unavailable'" (click)="seatSelect($event,result, 16)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">16</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('17'))?'':'unavailable'" (click)="seatSelect($event,result, 17)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">17</div>
                                                                                            </div>
                                                
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('18'))?'':'unavailable'" (click)="seatSelect($event,result, 18)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">18</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('19'))?'':'unavailable'" (click)="seatSelect($event,result, 19)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">19</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('20'))?'':'unavailable'" (click)="seatSelect($event,result, 20)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">20</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('21'))?'':'unavailable'" (click)="seatSelect($event,result, 21)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">21</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('22'))?'':'unavailable'" (click)="seatSelect($event,result, 22)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">22</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('23'))?'':'unavailable'" (click)="seatSelect($event,result, 23)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">23</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('24'))?'':'unavailable'" (click)="seatSelect($event,result, 24)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">24</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('25'))?'':'unavailable'" (click)="seatSelect($event,result, 25)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">25</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('26'))?'':'unavailable'" (click)="seatSelect($event,result, 26)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">26</div>
                                                                                            </div>
                                                
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('27'))?'':'unavailable'" (click)="seatSelect($event,result, 27)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">27</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('28'))?'':'unavailable'" (click)="seatSelect($event,result, 28)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">28</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('29'))?'':'unavailable'" (click)="seatSelect($event,result, 29)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">29</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('30'))?'':'unavailable'" (click)="seatSelect($event,result, 30)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">30</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('31'))?'':'unavailable'" (click)="seatSelect($event,result, 31)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">31</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('32'))?'':'unavailable'" (click)="seatSelect($event,result, 32)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">32</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('33'))?'':'unavailable'" (click)="seatSelect($event,result, 33)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">33</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('34'))?'':'unavailable'" (click)="seatSelect($event,result, 34)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">34</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('35'))?'':'unavailable'" (click)="seatSelect($event,result, 35)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">35</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('36'))?'':'unavailable'" (click)="seatSelect($event,result, 36)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">36</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('37'))?'':'unavailable'" (click)="seatSelect($event,result, 37)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">37</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('38'))?'':'unavailable'" (click)="seatSelect($event,result, 38)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">38</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('39'))?'':'unavailable'" (click)="seatSelect($event,result, 39)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">39</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('40'))?'':'unavailable'" (click)="seatSelect($event,result, 40)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">40</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('41'))?'':'unavailable'" (click)="seatSelect($event,result, 41)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">41</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('42'))?'':'unavailable'" (click)="seatSelect($event,result, 42)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">42</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('43'))?'':'unavailable'" (click)="seatSelect($event,result, 43)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">43</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('44'))?'':'unavailable'" (click)="seatSelect($event,result, 44)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">44</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('45'))?'':'unavailable'" (click)="seatSelect($event,result, 45)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">45</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('46'))?'':'unavailable'" (click)="seatSelect($event,result, 46)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">46</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('47'))?'':'unavailable'" (click)="seatSelect($event,result, 47)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">47</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('48'))?'':'unavailable'" (click)="seatSelect($event,result, 48)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">48</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('49'))?'':'unavailable'" (click)="seatSelect($event,result, 49)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">49</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('50'))?'':'unavailable'" (click)="seatSelect($event,result, 50)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">50</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('51'))?'':'unavailable'" (click)="seatSelect($event,result, 51)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">51</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('52'))?'':'unavailable'" (click)="seatSelect($event,result, 52)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">52</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('54'))?'':'unavailable'" (click)="seatSelect($event,result, 54)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">54</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('55'))?'':'unavailable'" (click)="seatSelect($event,result, 55)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">55</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('56'))?'':'unavailable'" (click)="seatSelect($event,result, 56)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">56</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('57'))?'':'unavailable'" (click)="seatSelect($event,result, 57)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">57</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('58'))?'':'unavailable'" (click)="seatSelect($event,result, 58)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">58</div>
                                                                                            </div>
                                                                                            <div class="no-single-seat"></div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('59'))?'':'unavailable'" (click)="seatSelect($event,result, 59)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">59</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('60'))?'':'unavailable'" (click)="seatSelect($event,result, 60)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">60</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('61'))?'':'unavailable'" (click)="seatSelect($event,result, 61)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">61</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="seat-row">
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('62'))?'':'unavailable'" (click)="seatSelect($event,result, 62)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">62</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('63'))?'':'unavailable'" (click)="seatSelect($event,result, 63)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">63</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('64'))?'':'unavailable'" (click)="seatSelect($event,result, 64)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">64</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('65'))?'':'unavailable'" (click)="seatSelect($event,result, 65)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">65</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('66'))?'':'unavailable'" (click)="seatSelect($event,result, 66)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">66</div>
                                                                                            </div>
                                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('67'))?'':'unavailable'" (click)="seatSelect($event,result, 67)" class="seat u-ib single-seat">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                                <div class="seat-no">67</div>
                                                                                            </div>
                                                                                        </div>
                                                
                                                
                                                                                            </div>
                                                                                        </div>
                                                
                                                
                                                                                    </div>
                                                                                </div>
                                                                             </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="modal-footer sidebar-footer " *ngIf="totalFare > 0">
                                                                        <p><b><span  class="text-green price-labels-lg"> Ksh {{totalFare}}</span></b>
                                                                        </p>
                                                                        <button (click)="submitForm(result)" type="submit" class="btn btn-secondary reserve" data-dismiss="modal" aria-label="Close" [ngClass]="( totalFare > 0) ? 'enabled':'disabled'" >BOOK NOW</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                         <div class="col-md-12 d-block d-sm-none d-md-block d-block d-md-none  d-block d-lg-none ">
                                                           <div class="book-section" *ngIf="totalFare > 0">
                                                               <div class="bus-booking-summary">
                                                                   <div class="summ-heading">Selected Seat </div>
                                                                   <span *ngFor="let seatvalue of seatsSelected" class="selected-seats">{{seatvalue}}<b *ngIf="seatsSelected.length > 1">, </b></span>
                                                                   <span *ngIf="seatsSelected.length ==0">-</span>
                                                                   <div class="fare-dtl-row total">
                                                                       <div class="left-wing u-ib u-v-align-middle">Fare: </div>
                                                                       <div class="right-wing u-ib u-v-align-middle">
                                                                           <div class="c-price-display u-text-ellipsis " title=""><span class="icon"><i
                                                                                       class="ixi-icon-inr icon" aria-hidden="true"></i></span>
                                                                                       <span  class="">{{totalFare}}</span>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                                   <button (click)="submitForm(result)" type="submit" [ngClass]="( totalFare > 0) ? 'enabled':'disabled'"  class="booking-btn  u-link">BOOK NOW</button>
                                                               </div>
                                                           </div>
                                                          </div>



                                                        <div class="u-clear"></div>

                                                  </div>
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                <div class="row">
                                    <div id="multiselect-error" class="col-lg-12 rides-list" data-group="direct"
                                        style="display: none">
                                        <div class="search-result">
                                            <div class="search-result__not-found">
                                                <div class="search-result-suggestions--cities"></div>
                                                <div class="search-result__not-found__text">Unfortunately there
                                                    are no trips on this route on
                                                    the selected date. Please adjust the date of the trip to get
                                                    results.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
                <div class="offer-section u-ib u-layout-col-2">

                </div>
            </div>
        </div>
      </div>
    </div>
      <div class="theme-page-section theme-page-section-white">
        <div class="container">
          <div class="row row-col-static" id="sticky-parent">
            <div class="col-md-11">
              <div class="theme-search-results _mb-10">
              </div>
            </div>
          </div>
        </div>
      </div>
<div class="container demo">
                                                    <div class="text-center">
                                                        <button type="button" class="btn btn-demo" data-toggle="modal" data-target="#exampleModal">
                                                         Sidebar Modal
                                                        </button>
                                                    </div>
                                                   
                                                </div>
<app-footer></app-footer>
