import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {Destinations } from '../models/index'
import {HomeService,DataService } from '../services/index'
import { formatDate  } from '@angular/common';
import {Router} from "@angular/router"
import { Base64 } from 'js-base64';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface City {
  id: number;
  text: string;
  hide_from_api: boolean;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})


export class HomeComponent implements OnInit {
  bsInlineValue = new Date();
  reactiveForm: UntypedFormGroup;
  returnForm: UntypedFormGroup;
  destinationLoading=false;
  pickup='';
  dropoff='';
  From ="From";
  To ="To";
  traveldate='';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today=new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Depature';
  public destplaceholder: string = 'Destination';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked=false;
  isPickupClicked=false;
  returning=false;
  //traveldate:any;
  data = [];
  destinationslists=[];
  msg = '';
  formdata={};
  submitted:boolean = false;
  submittedReturn:boolean = false;
  citires:any;
  loading: boolean;
  form = new UntypedFormGroup({
    traveldate: new UntypedFormControl(new Date()),
  });

    //material forms intergration
    filteredOptions: Observable<string[]>;
    filteredDropoffOptions: Observable<string[]>;
    returnfilteredOptions: Observable<string[]>;
    returnfilteredDropoffOptions: Observable<string[]>;
    private optionsSubject: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([]);
    private optionsSubjectDropoff: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([]);

    options$: Observable<City[]> = this.optionsSubject.asObservable();
    optionsDropoff$: Observable<City[]> = this.optionsSubjectDropoff.asObservable();
    defaultCities:City[] = [
      {
        id:4,
        text:"Nairobi",
        hide_from_api:false
        },
        {
          id:6,
          text:"Kisumu",
          hide_from_api:false
          },
          {
            id:41,
            text:"Kakamega",
            hide_from_api:false
          },
          {
            id:9,
            text:"Kitale",
            hide_from_api:false
          },
          {
            id:19,
            text:"Bungoma",
            hide_from_api:false
          },

      ];
  
  
  constructor(
    private service : HomeService,
    private dataService : DataService,
    private router:Router,
    private _fb: UntypedFormBuilder
        ) {
          this.reactiveForm = _fb.group({
            pickup:['', Validators.required],
            dropoff:['', Validators.required],
            traveldate:[new Date(), Validators.required],
            // returndate:['']
          });

          this.returnForm = _fb.group({
            pickupReturn:['', Validators.required],
            dropoffReturn:['', Validators.required],
            traveldateReturn:[new Date(), Validators.required],
            returndate:[new Date(), Validators.required]
          });
        
    }
    ngOnInit() {
      this.loadDestinations();
      this.filteredOptions = combineLatest([
        this.pickupControl.valueChanges.pipe(startWith('')),
        this.options$
    ]).pipe(
        map(([value, options]) => {
          
            // If the input is empty, show default cities first
            if (value === '') {
                return this.defaultCities.concat(options.filter(option => !this.defaultCities.some(defaultCity => defaultCity.id === option.id)));
            } else {
                return this._filter(value, options);
            }
        })
    );
  
      this.filteredDropoffOptions = combineLatest([
        this.dropoffControl.valueChanges.pipe(startWith('')),
        this.optionsDropoff$
      ]).pipe(
        map(([value, options]) => {
          
          // If the input is empty, show default cities first
          if (value === '') {
              return this.defaultCities.concat(options.filter(option => !this.defaultCities.some(defaultCity => defaultCity.id === option.id)));
          } else {
              return this._filter(value, options);
          }
      })
      );
  
      this.returnfilteredOptions = combineLatest([
        this.returnPickupControl.valueChanges.pipe(startWith('')),
        this.options$
      ]).pipe(
        map(([value, options]) => {
          
          // If the input is empty, show default cities first
          if (value === '') {
              return this.defaultCities.concat(options.filter(option => !this.defaultCities.some(defaultCity => defaultCity.id === option.id)));
          } else {
              return this._filter(value, options);
          }
      })
      );
  
      this.returnfilteredDropoffOptions = combineLatest([
        this.returnDropoffControl.valueChanges.pipe(startWith('')),
        this.options$
      ]).pipe(
        map(([value, options]) => {
          
          // If the input is empty, show default cities first
          if (value === '') {
              return this.defaultCities.concat(options.filter(option => !this.defaultCities.some(defaultCity => defaultCity.id === option.id)));
          } else {
              return this._filter(value, options);
          }
      })
      );
  
  
    }

    private _filter(value: string, options: any[]): any[] {
      const filterValue = value.toLowerCase();
      return options.filter(option => option.text.toLowerCase().includes(filterValue));
    }
  
    private _filterDropoff(value: string, options: any[]): any[] {
      const filterValue = value.toLowerCase();
      const filteredList = options.filter(option => option.text.toLowerCase().includes(filterValue));
      return this.defaultCities.concat(filteredList.filter(city => !this.defaultCities.includes(city.name)));
      // return options.filter(option => option.toLowerCase().includes(filterValue));
    }
  
    get pickupControl() {
      return this.reactiveForm.get('pickup');
    }
  
    get dropoffControl() {
      return this.reactiveForm.get('dropoff');
    }
  
    get returnPickupControl() {
      return this.returnForm.get('pickupReturn');
    }
  
    get returnDropoffControl() {
      return this.returnForm.get('dropoffReturn');
    }
    

    clearInput(which) {
      if(which === 'pickup'){
        this.pickupControl.setValue('');
      }else if(which ==='dropoff'){
        this.dropoffControl.setValue('');
      }else if(which ==='pickupReturn'){
        this.returnPickupControl.setValue('');
      }else{
        this.returnDropoffControl.setValue('');
      }
    }

  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e){
    this.isClicked=true;
  }

  returnClicked =() =>{
    this.returning=true;
  }

  closeReturn(){
    this.returning=false;
  }

  onClosed(e){
    this.isClicked=false;
  }

  onPickupFocused(e){
    this.isClicked=true;
    this.isPickupClicked=true;

  }
  onPickupClosed(e){
    this.isClicked=false;
    this.isPickupClicked=false;
  }


  submitForm() {
    this.submitted = true;
     let value = this.reactiveForm.value;
    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;
    var returndate='';
    if(this.returning){
      var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
       value.returndate=returndate;
    }



    const fpickup:any = this.data.filter(item => item.text === value.pickup);
    const fdropoff:any = this.data.filter(item => item.text === value.dropoff);

    this.formdata={
      'pickup' : fpickup[0].id,
      'dropoff' : fdropoff[0].id,
      'pickupname' : fpickup[0].text,//value.pickup=="1" ? this.data[value.pickup - 1].text:this.data[value.pickup - 2].text,
      'dropoffname' : fdropoff[0].text,//value.dropoff ==6 ? "Kisumu" : value.dropoff=="1" ? this.data[value.dropoff].text:this.data[value.dropoff - 2].text,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : this.returning
    }

    let p = Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
  }


  clickNairobiKTL(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      dropoff: "9",
      dropoffname: "Kitale",
      pickup: "4",
      pickupname: "Nairobi",
      traveldate : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])

  }

  clickNairobiMl(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "80",
      'dropoffname': "Malaba",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])

  }

  clickNairobiLk(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "72",
      'dropoffname': "Lwakhakha",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }

  clickNairobiBg(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "19",
      'dropoffname': "Bungoma",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }

  clickNairobiMu(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "96",
      'dropoffname': "Mumias",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }

  
  

  clickNairobiMb(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "89",
      'dropoffname': "Mbale",
      'pickup': "4",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
    
  }


  submitReturnForm() {
    this.submittedReturn = true;
     let value = this.returnForm.value;
    var traveldate = formatDate(value.traveldateReturn,"yyyy-MM-dd","en-KE");
    value.traveldateReturn=traveldate;
    var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
    value.returndate=returndate;
    this.formdata={
      'pickup' : value.pickupReturn,
      'dropoff' : value.dropoffReturn,
      'pickupname' : value.pickupReturn=="1" ? this.data[value.pickupReturn -1].text:this.data[value.pickupReturn - 2].text,
      'dropoffname' : value.dropoffReturn=="1" ? this.data[value.dropoffReturn].text:this.data[value.dropoffReturn - 2].text,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : true
    }



    
    let p=Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
  }



  private loadDestinations() {

    this.destinationLoading=true;

    this.service.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
        let citiesData=[];
          this.res.cities.forEach(city => {
            if(!city.hide_from_api){
                      let  ncity = {
                        id:city.id,
                        text:city.name,
                        hide_from_api:city.hide_from_api
                        }
                        citiesData.push(ncity);
                      }
          
          });
        this.data=citiesData;
     
        this.destinationslists=citiesData;
        this.optionsSubject.next(citiesData);
        this.optionsSubjectDropoff.next(citiesData)
        let cities=Base64.encode(JSON.stringify(this.data));
        localStorage.setItem('greeenline-cities', cities);
        this.destinationLoading=false;
      },
      error => {

      }
    );

  }

}
